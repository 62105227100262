'use client'
import { Segment } from "#app/(unauthorized)/authentication/jwt";
import { useJwt } from "#app/(unauthorized)/authentication/useJwt";
import { MyFaTeam } from "#app/_ui/components/Dashboard/MyFaTeam/MyFaTeam";
import { WelcomeHeader } from "#app/_ui/components/Dashboard/WelcomeHeader";
import SideBySide from "#app/_ui/layouts/SideBySide";
import { Banner } from "#ui/banner";
import { Container } from "#ui/container";
import { Main } from "#ui/main";
import { usePathname, useRouter } from "next/navigation";

export default function Page() {

  const jwt = useJwt();
  const activePath = usePathname();
  const router = useRouter();

  if(activePath === '/'){
    if(jwt.segment === Segment.CorporateEntities){
      router.push('/investing-tools/baird-research')
      return <></>
    }
    else if(jwt.segment === Segment.Preclient){
      router.push('/home')
      return <></>
    }
  }

  return (
    <Main>
      <Banner>
        <Container>
          <WelcomeHeader />
        </Container>
      </Banner>
      <Container>
        <SideBySide columns={2}>
          <div>
            <MyFaTeam />
          </div>
          <div>
            <>home page</>
          </div>
        </SideBySide>
      </Container>
    </Main>
  );
}
